import { Col, Row, Text } from "@nextui-org/react";
import React from "react";
import HeadersReceiving from "./HeadersReceiving";
import HeadersSending from "./HeadersSending";
import LoadJS from "./LoadJS";
import RestAPI from "./RestAPI";

const FeaturesPortfolio = () => {
  return (
    <>
      <div style={{ maxWidth: "40vw" }}>
        <Row>
          <Col>
            <Text>See what HTTP Headers is my browser SENDING</Text>
            <HeadersSending />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Text>See what HTTP Headers is my browser RECEIVING</Text>
            <HeadersReceiving />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <LoadJS />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Text>Make a REST API call</Text>
            <RestAPI />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FeaturesPortfolio;
