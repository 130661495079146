import { Button, Col, Loading, Row, Spacer, Text } from "@nextui-org/react";
import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

const HeadersSending = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState([]);

  const fetchHeaders = () => {
    setIsLoading(true);
    const functions = getFunctions();
    const returnHeaders = httpsCallable(functions, "returnHeaders");

    returnHeaders()
      .then((res) => {
        const headers = res.data;
        setHeaders(
          Object.keys(headers).map((key) => {
            return {
              name: key,
              value: headers[key],
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert("Request failed");
        setIsLoading(false);
      });
  };

  const renderTable = () => {
    if (headers.length === 0) return null;
    return (
      <>
        <Spacer y={0.5} />
        {/* <Text>
          These are the Request headers sent by your browser to an example POST
          call
        </Text> */}
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Text>
              <table>
                <tr>
                  <th>Header Name</th>
                  <th>Header Value</th>
                </tr>
                {headers.map((header) => {
                  return (
                    <tr>
                      <td>{header.name}</td>
                      <td>{header.value}</td>
                    </tr>
                  );
                })}
              </table>
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div>
        <Spacer y={0.5} />
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Button disabled={isLoading} onClick={() => fetchHeaders()}>
              {isLoading ? (
                <Loading type="points" color="white" size="sm" />
              ) : (
                <>Show Request Headers</>
              )}
            </Button>
          </Col>
        </Row>
        {isLoading ? null : renderTable()}
      </div>
    </>
  );
};

export default HeadersSending;
