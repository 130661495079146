import { Spacer, Text } from "@nextui-org/react";
import React, { useEffect } from "react";

const LoadJS = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/files/sample.js";
    script.async = true;

    document.body.appendChild(script);
  }, []);
  return (
    <>
      <div>
        <Spacer y={0.5} />
        <Text color="success">Loaded a JS file</Text>
        <script src="/files/sample.js" />
      </div>
    </>
  );
};

export default LoadJS;
