import {
  Button,
  Col,
  Input,
  Loading,
  Row,
  Spacer,
  Text,
} from "@nextui-org/react";
import React, { useState } from "react";

const axios = require("axios");

const RestAPI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseBody, setResponseBody] = useState(null);
  const [address, setAddress] = useState("https://testheaders.com/exampleAPI");

  const doWork = () => {
    setIsLoading(true);

    axios
      .get(address)
      .then((res) => {
        const data = JSON.stringify(res.data, undefined, 2);
        setResponseBody(data);
        setIsLoading(false);
      })
      .catch((err) => {
        alert("Request failed");
        setIsLoading(false);
      });
  };

  const renderTable = () => {
    if (!responseBody) return null;
    return (
      <>
        <Spacer y={0.5} />
        <Text>Response:</Text>
        <Spacer y={0.5} />
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <pre>
              <code>{responseBody}</code>
            </pre>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div>
        <Row justify="center" align="center">
          <Col span={12}>
            <Input
              type="url"
              placeholder="https://testheaders.com/exampleAPI"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              width="100%"
            />
          </Col>
        </Row>
        <Spacer y={0.5} />
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Button disabled={isLoading} onClick={() => doWork()}>
              {isLoading ? (
                <Loading type="points" color="white" size="sm" />
              ) : (
                <>Fetch Response</>
              )}
            </Button>
          </Col>
        </Row>
        {isLoading ? null : renderTable()}
      </div>
    </>
  );
};

export default RestAPI;
