import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// Web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyByEqH2kkRyE90w4K3f3cgrNeQk2IFlzjc",
  authDomain: "testheaders-74c3a.firebaseapp.com",
  databaseURL: "https://testheaders-74c3a-default-rtdb.firebaseio.com",
  projectId: "testheaders-74c3a",
  storageBucket: "testheaders-74c3a.appspot.com",
  messagingSenderId: "723489776166",
  appId: "1:723489776166:web:aeca3de034a149bc4cfce7",
  measurementId: "G-7L1JK460YW",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);

// If you're using Firebase Emulator, uncomment this code to enable react app use the Emulator
if (
  window.location.host.includes("localhost") ||
  window.location.host.includes("127.0.0.1")
) {
  const functions = getFunctions(firebaseApp);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
