import {
  Button,
  Col,
  Input,
  Loading,
  Row,
  Spacer,
  Text,
} from "@nextui-org/react";
import React, { useState } from "react";
const axios = require("axios");

const HeadersReceiving = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [address, setAddress] = useState("https://testheaders.com/exampleAPI");

  const fetchHeaders = () => {
    setIsLoading(true);

    axios
      .get(address)
      .then((res) => {
        const headers = res.headers;
        setHeaders(
          Object.keys(headers).map((key) => {
            return {
              name: key,
              value: headers[key],
            };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        alert("Request failed");
        setIsLoading(false);
      });
  };

  const renderTable = () => {
    if (headers.length === 0) return null;
    return (
      <>
        <Spacer y={0.5} />
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Text>
              <table>
                <tr>
                  <th>Header Name</th>
                  <th>Header Value</th>
                </tr>
                {headers.map((header) => {
                  return (
                    <tr>
                      <td>{header.name}</td>
                      <td>{header.value}</td>
                    </tr>
                  );
                })}
              </table>
            </Text>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <div>
        <Row justify="center" align="center">
          <Col span={12}>
            <Input
              type="url"
              placeholder="https://testheaders.com/exampleAPI"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              width="100%"
            />
          </Col>
        </Row>
        <Spacer y={0.5} />
        <Row justify="center" align="center">
          <Col justify="center" align="center">
            <Button disabled={isLoading} onClick={() => fetchHeaders()}>
              {isLoading ? (
                <Loading type="points" color="white" size="sm" />
              ) : (
                <>Show Response Headers</>
              )}
            </Button>
          </Col>
        </Row>
        {isLoading ? null : renderTable()}
      </div>
    </>
  );
};

export default HeadersReceiving;
