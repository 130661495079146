import "./App.css";
import { Text, Spacer, Link } from "@nextui-org/react";
import { Switch } from "@nextui-org/react";
import useDarkMode from "use-dark-mode";
import { FaMoon, FaSun } from "react-icons/fa";
import FeaturesPortfolio from "./components/FeaturesPortfolio";
import "./firebase.js";

function App() {
  const darkMode = useDarkMode(false);

  return (
    <div className="App">
      <header className="App-header">
        <Text
          h1
          size={60}
          css={{
            textGradient: "45deg, $blue500 -20%, $pink500 50%",
          }}
          weight="bold"
        >
          TestHeaders.com
        </Text>
        <Text
          css={{
            textGradient: "45deg, $purple500 -20%, $pink500 100%",
          }}
        >
          Utilities for testing HTTP headers
        </Text>
        <Spacer />
        <FeaturesPortfolio />
      </header>
      <Spacer />
      <footer className="App-footer">
        <Switch
          initialChecked={darkMode.value}
          onChange={() => darkMode.toggle()}
          iconOn={<FaSun />}
          iconOff={<FaMoon color="black" />}
        />
        <Spacer />
        <Text>
          Powered by{" "}
          <Link href="http://requestly.io/" target="_blank">
            requestly.io
          </Link>
        </Text>
      </footer>

      <Spacer />
    </div>
  );
}

export default App;
